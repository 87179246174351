
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import moment from "moment";
import store from "@/store";

export default defineComponent({
  name: "CZSavingsVsBudget",

  components: {},
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Savings Vs Budget", ["Flexible Procurement"]);
      savingsBudget();
    });

    const loading = ref<boolean>(false);
    watch(
      () => [
        store.getters.layoutConfig("flexibleProcurement.scenarioName"),
        store.getters.layoutConfig("flexibleProcurement.reportName"),
        store.getters.layoutConfig("flexibleProcurement.current"),
      ],
      function () {
        savingsBudget();
      }
    );

    const chartOptions = {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
      },
      grid: {
        show: true,
      },
      plotOptions: {
        bar: {
          borderRadius: 1,
          columnWidth: 25 + "%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        type: "datetime",
        labels: {
          datetimeUTC: false,
          formatter: function (val, timestamp) {
            return moment(new Date(timestamp)).format("MMM");
          },
          style: {
            fontSize: "12px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 500,
          },
        },
        title: {
          text: "Savings V Budget",
          style: {
            fontSize: "20px",
            fontWeight: 600,
            cssClass: "apexcharts-yaxis-title",
          },
        },

        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: true,
        },
      },
      yaxis: {
        title: {
          text: "£",
          style: {
            fontSize: "20px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 600,
            cssClass: "apexcharts-yaxis-title",
          },
        },
      },
    };

    const series = reactive([{}]);
    series.splice(0);

    /**
     *@description For getting savings vs budget report
     */
    function savingsBudget() {
      loading.value = true;

      let AccountId = store.getters.AccountId;
      let current = store.getters.layoutConfig("flexibleProcurement.current");
      let FPreportName = store.getters.layoutConfig(
        "flexibleProcurement.reportName"
      );
      let FPscenarioName = store.getters.layoutConfig(
        "flexibleProcurement.scenarioName"
      );

      const accountInfo: any = {
        clientId: AccountId,
        productId: 4,
        reportId: FPreportName,
        scenarioId: FPscenarioName,
        iscurrent: current,
      };
      ApiService.post("api/FlexProcurement/getFlexProcrement", accountInfo)
        .then(async ({ data }) => {
          const response = await data.dayCostList;
          series.splice(0);
          series.push({
            name: "Savings V Budget",
            data: response.map((a) => [new Date(a.date), a.savings]),
          });
          loading.value = false;
        })
        .catch((a) => {
          loading.value = false;
          console.log("Api Log Error", a);
        });
    }
    return {
      series,
      chartOptions,
      loading,
    };
  },
});
